<template>
  <v-menu v-model="notificationDrawer" :close-on-content-click="false" max-height="97vh">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-email-outline</v-icon>
        <v-badge color="warning" :content="notificationCount" :value="notificationCount"></v-badge>
      </v-btn>
    </template>
    <v-card width="500">
      <v-card-title class="stickyTitle">Notificaties
        <v-chip small class="ml-2">{{ notificationCount }}</v-chip>
        <v-spacer />
        <v-btn text @click.stop="notificationDrawer = !notificationDrawer" small><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-progress-linear v-if="fetchingNotifications" indeterminate color="primary" class="ma-0"></v-progress-linear>
      <v-card-text style="overflow-y: auto;">
        <span v-if="notificationError" class="error--text">Er is een fout opgetreden bij het ophalen van de
          notificaties</span>
        <span v-else-if="!notificationCount && !fetchingNotifications">Er zijn geen nieuwe notificaties</span>
        <v-list v-else >
          <template v-for="(item, key, index) in notifications" >
            <v-list-item :key="key" @click.stop="goToSubject(item[0])">
              <v-list-item-content>
                <v-row>
                  <v-col cols="8">
                    <v-list-item-title class="d-flex align-center text-wrap">
                      {{ item[0]?.title?.replace('[URGENT]', '') }}
                      <span v-if="item.length > 1" class="ml-2 text--secondary">+&nbsp;{{ item.length - 1 }} andere {{ item.length - 1 > 1 ? 'updates' : 'update'}}</span>
                      <v-icon v-if="item[0]?.title?.startsWith('[URGENT]')" color="error" class="ma-0 ml-1" title="URGENT">mdi-alert-circle</v-icon>
                    </v-list-item-title>
                    <v-list-item-subtitle :title="item[0]?.shortBody" class="d-inline-flex text-caption mb-1 text-wrap">
                      {{ $moment.utc(item[0]?.created).local().format('DD-MM HH:mm') }}, {{ $moment.utc(item[0]?.created).fromNow(true) }} geleden
                    </v-list-item-subtitle>
                    <v-list-item-subtitle :title="item[0]?.shortBody">
                      {{ item[0]?.shortBody }}
                    </v-list-item-subtitle>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center justify-end">
                    <v-btn icon dense @click.stop="markAsRead(item[0].id)"><v-icon>mdi-close</v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider
            v-if="index < notificationCount - 1"
            :key="index"
          ></v-divider>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="notificationCount" text color="primary" small :loading="markingAllAsRead" @click.stop="markAllAsRead()">Alles wissen</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'NotificationListCard',
  data() {
    return {
      loading: false,
      notifications: [],
      notificationDrawer: false,
      notificationError: false,
      markingAllAsRead: false,
      fetchingNotifications: false
    };
  },
  computed: {
    notificationCount() {
      return Object.keys(this.notifications)?.length;
    }
  },
  methods: {
    getNotifications(retryCount = 0) {
      this.fetchingNotifications = true;
      this.$http.get('api/notification/')
        .then(request => {
          this.notificationError = false;
          this.notifications = _.groupBy(request.data, x => x.objectId)
          this.fetchingNotifications = false;
        })
        .catch((e) => {
          if (e.response && e.response.status === 401 && retryCount < 3) {
            setTimeout(() => {
              this.getNotifications(retryCount + 1);
            }, 1000);
          } else {
            console.log('notifications error!');  /* eslint-disable-line no-console */
            this.notificationError = true;
            this.fetchingNotifications = false;
          }
        })
    },
    markAllAsRead() {
      this.markingAllAsRead = true;
      this.$http.get('api/notification/markallasread')
        .then(() => {
          this.getNotifications();
        })
        .catch(err => {
          this.$root.$emit("display-error", "1200", err);
        })
        .finally(() =>
        {
          this.markingAllAsRead = false; 
          this.notificationDrawer = false;
        });
    },
    markAsRead(id) {
      if (!id) return;
      this.$http.get(`api/notification/markasread/${id}`)
        .then(() => {
          this.getNotifications();
        })
        .catch(() => {
          console.log('notification mark as read error!');  /* eslint-disable-line no-console */
        })
    },
    goToSubject(notification) {
      if (!notification) return;
      switch (notification.objectType) {
        case "Ticket":
          this.$router.push({ name: 'Ticket', params: { id: notification.objectId } });
          break;
        case "Project":
          this.$router.push({ name: 'Project', params: { id: notification.objectId } });
          break;
        case "Installation":
          this.$router.push({ name: 'Installation', params: { id: notification.objectId } });
          break;
        default:
          break;
      }
      this.notificationDrawer = false;
      this.markAsRead(notification.id);
    }
  },
  mounted() {
    this.getNotifications();
    setInterval(() => this.getNotifications(), 60000);
  }
}
</script>

<style scoped>
.stickyTitle {
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
  background-color: white !important;
}
</style>