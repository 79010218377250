import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Touch, Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  directives: {
    Touch,
    Ripple,
  },  
  theme: {
    themes: {
      light: {
        accent: '#C8D1D6',
        anchor: '#8C9EFF',
        primary: '#006BAC',
        secondary: '#485156',
        info: '#C8D1D6',
        warning: '#F39000',
        backdrop: '#E4E7E9'
      }
    }
  },
  icons: {
    iconfont: 'mdi',
  },
})

export default vuetify;