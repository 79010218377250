<template>
  <v-dialog v-model="open" :width="width" @click:outside="!persistent && $emit('close')" :persistent="persistent">
    <v-card :class="`px-2 pb-2 ${title ? '' : 'pt-2'}`">
      <v-card-title v-if="title">{{ title }}</v-card-title>
      <v-card-text class="text--primary">
        {{ message }}
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn text @click="$emit('close')" class="mr-1">annuleren</v-btn>
        <v-btn v-if="type === 'delete'" color="error" :loading="loading" @click="$emit('agree')">
          verwijderen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfirmationDialog",
  props: ['title', 'width', 'message', 'open', 'type', 'loading', 'persistent'],
  model: {
    prop: 'open',
    event: 'toggle'
  },
}
</script>