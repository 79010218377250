<template>
  <v-card>
    <v-card-title>
      Nieuwe melding
      <v-spacer></v-spacer>
      <v-btn @click=closeDialog text>
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-stepper v-model="step" vertical flat>
        <v-stepper-step :complete="step > 1" step="1" :editable="step > 1">
          Melder
          <span class="selected" v-if="reporter && step > 1"> -
            <span v-if="reporterOrganizationId">{{ getOrganizationNameById(reporterOrganizationId) }} | </span>
            {{ getDisplayName(reporter) }} |
            {{ reporter.email }}</span>
        </v-stepper-step>
        <v-stepper-content step="1">
          <div v-if="!otherReporter">
            <p class="text-body-1 mb-1">{{ $root.myName }}</p>
            <p class="text-body-2">{{ $root.me.email }}</p>
          </div>
          <v-checkbox v-model="otherReporter" label="Andere melder"></v-checkbox>
          <TreeviewSelect v-if="otherReporter" label="Organisatie" :rawItems="organizationsData" itemKey="id"
            v-model="reporterOrganizationId" outlined />
          <v-autocomplete v-if="otherReporter && !newReporterPerson && reporterOrganizationId" v-model="ticket.reporterId"
            item-value="id" :item-text="getDisplayName" :search.sync="otherReporterNameSearch" :items="organizationPeople"
            hide-selected label="Melder" :rules="[v => !!v || 'Organisatie is verplicht']" outlined dense>
            <template v-slot:selection="{ attr, on, item, selected }">
              <span v-bind="attr" :input-value="selected" v-on="on" v-text="getDisplayName(item)"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="getDisplayName(item)"></v-list-item-title>
                <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.mobilePhone" v-text="item.mobilePhone"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                    Geen persoon gevonden
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:append-item>
              <v-list-item @click="newReporterPerson = true">
                <v-list-item-content>
                  <v-list-item-title>
                    Nieuw persoon toevoegen..
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>

          <v-sheet v-if="newReporterPerson">
            <v-text-field v-model="reporter.email" persistent-hint
              hint="Dit veld is belangrijk voor communicatie en toegang tot portaal!" outlined dense counter="50"
              maxlength="50" :rules="[v => (!v || /.+@.+\..+/.test(v.trim())) || 'Ongeldig e-mail adres']" label="E-mail"
              :disabled="!reporterOrganizationId" @blur="findPersonByEmail()"></v-text-field>
            <v-form v-model="newReporterForm" v-if="reporter.email && reporter.id == $root.emptyGuid">
              <v-text-field v-model="reporter.firstname" outlined dense counter="50" maxlength="50"
                :rules="[v => !!v || 'Voornaam is verplicht']" label="Voornaam">
              </v-text-field>
              <v-text-field v-model="reporter.surnamePrefix" outlined dense counter="20" maxlength="20"
                label="Tussenvoegsel">
              </v-text-field>
              <v-text-field v-model="reporter.surname" outlined dense counter="100" maxlength="100"
                :rules="[v => !!v || 'Achternaam is verplicht']" label="Achternaam">
              </v-text-field>
              <v-text-field v-model="reporter.mobilePhone" outlined dense
                :rules="[v => (!v || this.$root.phoneRegex.test(v.trim())) || 'Ongeldig telefoonnummer']"
                label="Telefoon"></v-text-field>
              <v-spacer></v-spacer>
            </v-form>
            <v-alert dense color="primary" outlined v-if="reporter.id != $root.emptyGuid">Deze persoon is al bekend en
              zal worden gekoppeld aan de geselecteerde organisatie</v-alert>
          </v-sheet>
          <v-btn v-if="!otherReporter || (ticket.reporterId != $root.emptyGuid || newReporterForm)" color="primary"
            @click="step++">
            Volgende
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="ticket.type !== null ? true : false" step="2"
          :editable="ticket.type !== null ? true : false && step > 2">
          Type
          <span class="selected" v-if="ticket.type !== null">
            - {{ getTicketType(ticket.type)?.name }}
          </span>
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-list rounded dense>
            <v-list-item-group v-model="ticket.type" active-class="border" color="primary">
              <v-list-item v-for="ticketType in $root.ticketTypes" :value="ticketType.id" :key="ticketType.id">
                <v-list-item-content>
                  <v-list-item-title v-text="ticketType.name" />
                  <v-list-item-subtitle v-text="ticketType.description" class="text-wrap" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-stepper-content>


        <v-stepper-step :complete="ticket.categoryId ? true : false" step="3"
          :editable="ticket.categoryId ? true : false && step > 3">
          Categorie
          <span class="selected" v-if="ticket.categoryId"> - {{ categories?.find(c => c.id ===
            ticket.categoryId)?.name
          }}</span>
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-list rounded dense>
            <v-list-item-group v-model="ticket.categoryId" active-class="border" color="primary">
              <v-list-item v-for="category in categories" :value="category.id" :key="category.id">
                <v-list-item-content>
                  <v-list-item-title v-text="category.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="category.description" class="text-wrap"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-stepper-content>

        <v-stepper-step :complete="ticket.subCategoryId ? true : false" step="4"
          :editable="ticket.subCategoryId ? true : false && step > 4">
          Subcategorie
          <span class="selected" v-if="ticket.subCategoryId"> - {{ subCategories?.find(c =>
            c.id === ticket.subCategoryId)?.name
          }}</span>
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-list rounded dense>
            <v-list-item-group v-model="ticket.subCategoryId" color="primary">
              <v-list-item v-for="subCategory in subCategories" :value="subCategory.id" :key="subCategory.id">
                <v-list-item-content>
                  <v-list-item-title v-text="subCategory.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="subCategory.description" class="text-wrap"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-stepper-content>

        <v-stepper-step v-if="selectedCategory?.simpleSeverity && ticket.type === 0"
          :complete="ticket.priority !== null && ticket.impact !== null" step="5"
          :editable="ticket.priority !== null && ticket.impact !== null && step > 5">
          Fataal of niet fataal
          <span class="selected" v-if="ticket.priority == 3 && ticket.impact == 3"> - Fataal</span>
          <span class="selected" v-else-if="ticket.priority == 1 && ticket.impact == 1"> - Niet fataal</span>
        </v-stepper-step>
        <v-stepper-content v-if="selectedCategory?.simpleSeverity && ticket.type === 0" step="5">
          <v-chip-group v-model="simpleSeverity" row active-class="primary--text" mandatory>
            <v-chip class="mx-2" v-for="item in fatalityOptions" :key="item.id" :value="item.value" :active="!item.hidden"
              outlined>{{ item.text }}</v-chip>
          </v-chip-group>
        </v-stepper-content>

        <v-stepper-step v-if="!selectedCategory?.simpleSeverity || ticket.type > 0"
          :complete="ticket.priority !== null && ticket.impact !== null" step="5"
          :editable="ticket.priority !== null && ticket.impact !== null && step > 5">
          Prioriteit en impact
          <span class="selected" v-if="ticket.priority !== null && ticket.impact !== null"> - {{
            $root.priorities[ticket.priority]?.name
          }} en {{ $root.impacts[ticket.impact]?.name }}</span>
        </v-stepper-step>
        <v-stepper-content v-if="!selectedCategory?.simpleSeverity || ticket.type > 0" step="5">
          <p class="text-subtitle-2 mb-1">Prioriteit</p>
          <v-chip-group v-model="selectedPriority" row active-class="primary--text">
            <v-chip class="mx-2" v-for="priority in $root.priorities" :key="priority.id" :value="priority.id" outlined>{{
              priority.name
            }}</v-chip>
          </v-chip-group>
          <p class="text-subtitle-2 mb-1 mt-3">Impact</p>
          <v-chip-group v-model="selectedImpact" row active-class="primary--text">
            <v-chip class="mx-2" v-for="impact in $root.impacts" :key="impact.id" :value="impact.id" outlined>
              {{ impact.name }}</v-chip>
          </v-chip-group>
        </v-stepper-content>

        <v-stepper-step :complete="validSubject" step="6" :editable="validSubject && step > 6">
          Onderwerp
          <span class="selected" v-if="ticket.installationId !== null && step > 5"> - {{
            getInstallationNameById(ticket.installationId)
          }}</span>
        </v-stepper-step>
        <v-stepper-content step="6">
          <v-form ref="subjectForm" v-model="validSubject">
            <v-autocomplete v-if="selectedCategory?.isInstallationRequired != false" v-model="ticket.installationId"
              item-text="searchQuery" item-value="id" :items="activeInstallations" hide-selected label="Installatie"
              :loading="loadingInstallations" :rules="selectedCategory?.isInstallationRequired ? [(v => !!v || 'Installatie is verplicht')] : []" outlined
              dense clearable>
              <template v-slot:item="{ item }">
                <div class="my-2">
                  <v-list-item-title>{{ getInstallationName(item) }}</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">{{ item.address?.description }}</v-list-item-subtitle>
                </div>
              </template>
              <template v-slot:selection="{ item }">
                {{ getInstallationName(item) }}
              </template>
              <template v-slot:no-data>
                    <span class="mx-2" v-if="loadingInstallations">Installaties laden...</span>
                    <span class="mx-2" v-else>Geen installaties gevonden</span>
                  </template>
            </v-autocomplete>
            <div class="mb-6" v-if="autoSelectedProject && project"><span
                class="font-weight-medium">Project:&nbsp;</span>{{
                  project?.erpReferenceId }}
              {{ project?.name }}</div>
            <v-autocomplete v-if="!autoSelectedProject" v-model="ticket.projectId" item-value="id"
              :items="computedProjects" :filter="filterDataProjects" hide-selected label="Contract/Project"
              :loading="loadingProjects" :rules="[v => !!v || 'Contract/Project is verplicht']" outlined dense clearable>
              <template v-slot:item="{ item }">
                {{ item.erpReferenceId }} {{ item.name }}
              </template>
              <template v-slot:selection="{ item }">
                {{ item.erpReferenceId }} {{ item.name }}
              </template>
            </v-autocomplete>
            <v-textarea v-model="ticket.shortDescription" label="Korte omschrijving" rows="1" auto-grow counter="100"
              outlined dense
              :rules="[v => !!v || 'Korte omschrijving is verplicht', v => (v && v.length <= 100) || 'Maximaal 100 karakters']">
            </v-textarea>
            <v-textarea v-model="ticket.problemDescription" label="Volledige omschrijving" rows="3" auto-grow
              counter="1000" outlined dense hint="Optioneel" maxlength="1000">
            </v-textarea>
          </v-form>
          <v-text-field v-model="ticket.clientTicketNumber" label="Ticketnummer klant" counter="50" maxlength="50"
            hint="Optioneel" persistent-hint outlined dense>
          </v-text-field>
          <v-btn :disabled="!validSubject" color="primary" @click="step++">
            Volgende
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="step > 7" step="7" :editable="step > 7">
          Bestanden
          <span class="selected text-primary" v-if="ticket.files.length > 0 && step > 7"> - {{
            fileNames
          }}</span>
          <small>Optioneel</small>
        </v-stepper-step>
        <v-stepper-content step="7">
          <v-file-input v-model="ticket.files" counter label="Bestanden" multiple outlined dense>
            <template v-slot:selection="{ text }">
              <v-chip dark small close @click:close="removeSelectedFile(text)">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-btn color="primary" @click="step++">
            Volgende
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="step > 8" step="8" :editable="step > 8">
          Contactpersoon
          <span class="selected" v-if="ticket.reporterIsContact && step > 8"> -
            {{ getDisplayName(reporter) }} | {{ reporter.email }}</span>
          <span class="selected" v-if="!ticket.reporterIsContact && ticket.contactName && step > 8"> - {{
            ticket.contactName
          }} | {{ ticket.contactEmail }} </span>
        </v-stepper-step>
        <v-stepper-content step="8">
          <div v-if="!otherContact">
            <p class="text-body-1 mb-1">{{ getDisplayName(reporter) }}</p>
            <p class="text-body-2">{{ reporter.email }}</p>
          </div>
          <v-checkbox v-model="otherContact" label="Ander contactpersoon">
          </v-checkbox>
          <v-form v-if="otherContact" ref="contactForm" v-model="validContact">
            <v-text-field v-model="ticket.contactName" outlined dense counter="200" maxlength="200"
              :rules="[v => !!v || 'Naam is verplicht']" label="Naam"></v-text-field>
            <v-text-field v-model="ticket.contactEmail" outlined dense counter="200" maxlength="200"
              :rules="[v => (!v || /.+@.+\..+/.test(v.trim())) || 'Ongeldig e-mail adres']" label="E-mail">
            </v-text-field>
            <v-text-field v-model="ticket.contactPhoneNumber" outlined dense
              :rules="[v => (!v || this.$root.phoneRegex.test(v.trim().replace(' ', ''))) || 'Ongeldig telefoonnummer']"
              label="Telefoon"></v-text-field>
            <v-btn color="primary" :disabled="!validContact" @click="step++">
              Volgende
            </v-btn>
          </v-form>
          <v-btn v-if="!otherContact" color="primary" @click="step++">
            Volgende
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="9">
          Bevestigen
        </v-stepper-step>
        <v-stepper-content step="9">
          <div class="d-flex flex-row align-center">
            <v-checkbox v-model="dataIsCorrect" label="Ingevulde gegevens zijn correct"></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn :disabled="!dataIsCorrect || (!validContact && otherContact) || !validSubject" color="success"
              @click="submit" :loading="submitting">
              verstuur
              <v-icon right>mdi-send</v-icon>
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from 'debounce';
import TreeviewSelect from '../components/TreeviewSelect.vue';
export default {
  components: { TreeviewSelect },
  name: 'TicketForm',
  data() {
    return {
      ticket: {
        type: null,
        categoryId: null,
        subCategoryId: null,
        reporterId: null,
        reporterIsContact: true,
        contactName: null,
        contactEmail: null,
        contactPhoneNumber: null,
        shortDescription: null,
        problemDescription: null,
        attachments: [],
        impact: null,
        priority: null,
        projectId: null,
        installationId: null,
        clientTicketNumber: null,
        firstLineSupportOnLocation: false,
        files: [],
      },
      reporterOrganizationId: null,
      reporter: {
        id: null,
        firstname: null,
        surnamePrefix: null,
        surname: null,
        email: null,
        mobilePhone: null
      },
      step: 1,
      simpleSeverity: null,
      installations: [],
      projects: [],
      categories: null,
      dialog: false,
      validSubject: false,
      validContact: true,
      selectedPriority: null,
      selectedImpact: null,
      otherContact: false,
      dataIsCorrect: false,
      createdTicketId: null,
      newFileForm: null,
      newFileName: '',
      submitting: false,
      otherReporter: false,
      newReporterForm: false,
      otherReporterNameSearch: null,
      newReporterPerson: false,
      organizationsData: [],
      organizationPeople: [],
      loadingInstallations: false,
      loadingProjects: false,
      fatalityOptions: [
        { id: 0, value: null, text: '', hidden: true },
        { id: 1, value: true, text: 'Fataal' },
        { id: 2, value: false, text: 'Niet fataal' }
      ],
      installationProjects: [],
      debounce: null,
      getInstallationsDebounced: null,
    }
  },
  watch: {
    'ticket.reporterId'(val) {
      if (val) {
        var person = this.organizationPeople.find(p => p.id == val);
        if (person) {
          this.reporter.id = person.id ?? null;
          this.reporter.firstname = person.firstname ?? null;
          this.reporter.surname = person.surname ?? null;
          this.reporter.surnamePrefix = person.surnamePrefix ?? null;
          this.reporter.email = person.email ?? null;
          this.reporter.mobilePhone = person.mobilePhone ?? null;
        }
      }
    },
    'ticket.installationId'(val) {
      if (this.selectedCategory?.fixedProjectId) return;
      if (val) {
        this.getProjectByInstallationId(val)
      } else {
        this.ticket.projectId = null;
      }
    },
    'ticket.categoryId'(val) {
      if (val) {
        if (this.selectedCategory?.fixedProjectId) {
          this.ticket.projectId = this.selectedCategory?.fixedProjectId;
        }
        this.ticket.subCategoryId = null;
        this.ticket.priority = null;
        this.selectedPriority = null;
        this.selectedImpact = null;
        this.ticket.impact = null;
        this.step++;
      } else {
        this.ticket.projectId = null;
      }
    },
    'ticket.subCategoryId'(val) {
      if (val) {
        this.step++;
      }
    },
    'ticket.type'(val) {
      if (val !== null) {
        this.step++;
      }
    },
    simpleSeverity(val) {
      if (val) {
        this.ticket.priority = this.$root.priorities.find(x => x.id == 3).id;
        this.ticket.impact = this.$root.impacts.find(x => x.id == 3).id;
      } else {
        this.ticket.priority = this.$root.priorities.find(x => x.id == 1).id;
        this.ticket.impact = this.$root.impacts.find(x => x.id == 1).id;
      }
      this.step++
    },
    selectedPriority() {
      if (this.selectedPriority != null && this.selectedImpact != null) {
        this.ticket.priority = this.selectedPriority;
        this.ticket.impact = this.selectedImpact;
        this.step++;
      }
    },
    selectedImpact() {
      if (this.selectedPriority != null && this.selectedImpact != null) {
        this.ticket.priority = this.selectedPriority;
        this.ticket.impact = this.selectedImpact;
        this.step++;
      }
    },
    otherContact() {
      this.ticket.reporterIsContact = !this.otherContact;
      if (!this.otherContact) {
        this.ticket.contactName = null;
        this.ticket.contactEmail = null;
        this.ticket.contactPhoneNumber = null;
        this.validContact = true;
      }
      else {
        this.validContact = false;
      }
    },
    step() {
      if (this.step < 7) {
        this.dataIsCorrect = false;
      }
    },
    otherReporter() {
      this.initializeReporter();
    },
    reporterOrganizationId(val) {
      if (val) this.getOrganizationPeople();
    }
  },
  computed: {
    activeInstallations(){
      if (this.reporterOrganizationId) return this.installations.filter(i => i.ownerId === this.reporterOrganizationId);
      else return this.installations;
    },
    selectedCategory() {
      return this.categories?.find(c =>
        c.id === this.ticket.categoryId);
    },
    subCategories() {
      if (this.ticket.categoryId) {
        return this.categories.find(c => c.id == this.ticket.categoryId).subCategories;
      }
      return [];
    },
    fileNames() {
      return this.ticket.files.map(f => f.name).join(', ');
    },
    computedProjects() {
      return this.installationProjects.length ? this.installationProjects : this.projects;
    },
    autoSelectedProject() {
      if (this.selectedCategory?.fixedProjectId) return true;
      if (this.installationProjects.length === 1) return true;
      return false;
    },
    project() {
      if (this.ticket.projectId) {
        return this.computedProjects?.map(p => ({ id: p.id, erpReferenceId: p.erpReferenceId, name: p.name })).find(p => p.id === this.ticket.projectId);
      }
      else {
        return null;
      }
    }
  },
  methods: {
    getTicketType(typeEnum){
      return this.$root.ticketTypes.find(x => x.id === typeEnum)
    },
    getInstallations() {
      this.loadingInstallations = true;
      let query = "api/installation?page=1&pagesize=100";
      this.$http
        .get(query)
        .then(request => {
          if (request.data?.installations?.length) {
            this.installations = request.data.installations.map(i=> {
              i.searchQuery = (i.name + i.owner?.name + i.address?.name + i.address?.description).toLowerCase();
              return i;
            });
          }
        })
        .catch(() => {
          console.log('Installation error'); /* eslint-disable-line no-console */
        })
        .finally(() => {
          this.loadingInstallations = false;
        });
    },
    getServiceCategories() {
      this.$http.get("api/servicecategory/user")
        .then(request => {
          if (request.data) {
            this.categories = request.data;
          }
        })
        .catch(() => {
          console.log('Category error'); /* eslint-disable-line no-console */
        });
    },
    closeDialog() {
      this.step = 1;
      this.$emit('close');
    },
    filterDataProjects(item, queryText) {
      return (
        item.name?.toLowerCase().includes(queryText.toLowerCase()) ||
        item.erpReferenceId?.toLowerCase().includes(queryText.toLowerCase())
      )
    },
    removeSelectedFile(item) {
      this.ticket.files.splice(this.ticket.files.indexOf(item), 1);
    },
    getInstallationName(item) {
      return `${item.name} - ${item.owner?.name ?? ''} ${item.address?.name ?? ''}`
    },
    getInstallationNameById(id) {
      var installation = this.installations.find(i => i.id == id);
      if (installation) {
        return this.getInstallationName(installation);
      }
    },
    getOrganizations() {
      this.$http.get('api/organization/')
        .then(request => {
          this.organizationsData = request.data;
        })
        .catch(() => { console.log('Organization api error!') }) /* eslint-disable-line no-console */
    },
    getProjects() {
      this.loadingProjects = true;
      this.$http.get('api/project/?active=true&service=true')
        .then(request => {
          this.projects = request.data;
        })
        .catch(() => { console.log('Project api error!') }) /* eslint-disable-line no-console */
        .finally(() => {
          this.loadingProjects = false;
        });
    },
    getProjectByInstallationId() {
      this.loadingProjects = true;
      this.$http.get('api/project/installation/' + this.ticket.installationId + '?active=true&includeSubProjects=true')
        .then(request => {
          if (request.status == 200) {
            this.installationProjects = request.data;
            this.ticket.projectId = this.installationProjects.toSorted((a, b) => a.startDate < b.startDate ? 1 : a.startDate > b.startDate ? -1 : 0)[0]?.id;
          }
        })
        .catch(() => { console.log('Project api error!') }) /* eslint-disable-line no-console */
        .finally(() => { this.loadingProjects = false; });
    },
    getOrganizationPeople() {
      this.$http.get('api/person/overview?organizationId=' + this.reporterOrganizationId)
        .then(request => {
          this.organizationPeople = request.data
        })
        .catch(() => { console.log('Organization api error!') }) /* eslint-disable-line no-console */
    },
    getOrganizationNameById(id) {
      var org = this.organizationsData.find(o => o.id == id);
      if (!org) return '';
      return org.name;
    },
    findPersonByEmail() {
      this.$http.get('api/person?email=' + this.reporter.email)
        .then(request => {
          this.reporter = request.data;
          this.ticket.reporterId = request.data.id;
        })
        .catch(() => {
          this.ticket.reporterId = this.$root.emptyGuid;
          this.reporter.id = this.$root.emptyGuid;
          this.reporter.firstname = null;
          this.reporter.surname = null;
          this.reporter.surnamePrefix = null;
          this.reporter.mobilePhone = null;

          console.log('Person not found!')  /* eslint-disable-line no-console */
        })
    },
    submit() {
      this.submitting = true;
      if (this.dataIsCorrect && this.validContact && this.validSubject) {
        this.$http.post('api/ticket', {
          reporter: this.reporter,
          reporterOrganizationId: this.reporterOrganizationId,
          ticket: this.ticket
        })
          .then((request) => {
            if (request.status === 201) {
              this.createdTicketId = request.data.id;
              if (this.ticket.files?.length > 0) {
                this.uploadFiles();
              }
              else {
                this.$emit('done');
              }
            }
          })
          .catch(err => {
            this.submitting = false;
            this.$root.$emit("display-message", "1200", err, "error");
          })
      }
    },
    uploadFiles() {
      let promises = this.ticket.files.map(file => {
        if (file > 10240000) {
          alert("Max. 10Mb");
          return;
        }
        this.newFileForm = new FormData();
        this.newFileName = file.name;
        this.newFileForm.append("file", file, file.name);
        return this.$http
          .post(`api/file?ticketId=${this.createdTicketId}`, this.newFileForm, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .catch(() => {
            this.submitting = false;
            this.$root.$emit("display-message", "1200", "De nieuwe melding is aangemaakt maar er is een fout opgetreden bij het uploaden van de bestanden.", "error");
          })
      });
      Promise.all(promises).finally(() => {
        this.$emit('done');
      })
    },
    getDisplayName(reporter) {
      return (reporter.firstname + ' ' + (reporter.surnamePrefix || '') + ' ' + reporter.surname).replace('  ', ' ');
    },
    initializeReporter() {
      if (this.otherReporter) {
        this.ticket.reporterId = this.$root.emptyGuid;
        this.reporter.id = this.$root.emptyGuid;
        this.reporter.firstname = null;
        this.reporter.surname = null;
        this.reporter.surnamePrefix = null;
        this.reporter.email = null;
        this.reporter.mobilePhone = null;
      } else {
        this.ticket.reporterId = this.$root.me.id;
        this.reporter.id = this.$root.me.id;
        this.reporter.firstname = this.$root.me.firstname;
        this.reporter.surname = this.$root.me.surname;
        this.reporter.surnamePrefix = this.$root.me.surnamePrefix;
        this.reporter.email = this.$root.me.email;
        this.reporter.mobilePhone = this.$root.me.mobilePhone;
      }
      this.newReporterPerson = false;
      this.reporterOrganizationId = null;
    },
  },
  mounted() {
    this.getOrganizations();
    this.getInstallations();
    this.getProjects();
    this.getServiceCategories();
    this.initializeReporter();
  },
  created() {
    var self = this;
    this.getInstallationsDebounced = debounce(() => self.getInstallations(), 500);
  }
}
</script>

<style scoped>
.selected {
  font-weight: bold;
}
</style>
